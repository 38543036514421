import { Field, Formik } from "formik";
import React from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { postAnswer } from "../../../api/api";
import arrow_left_gray from "../../../assets/images/icons/arrow_left_gray.svg";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";

export default function Question({ question, prevStep, nextStep, number }) {
    const { token, email } = useParams();
    const answerMutation = useMutation((values) => postAnswer(question?.id, values));

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        if (Array.isArray(values.value)) {
            values.value = values.value.join("|");
        }

        answerMutation.mutate(values, {
            onSuccess: () => {
                nextStep();
                resetForm();
            },
            onError: (error) => {
                if (error.response?.data?.message) {
                    setErrors({ value: error.response.data.message });
                } else {
                    setErrors({ value: "Er is iets fout gegaan" });
                }
                setSubmitting(false);
            },
        });
    };

    const getOtherInputBlock = (type) => {
        return (
            <>
                <div className={type} key="other">
                    {type === "radio" ? (
                        <Field id="other" name="value" type={type} className="form-control" value={"other"} />
                    ) : (
                        <Field id="other" name="other" type={type} className="form-control" />
                    )}
                    <label htmlFor="other">Anders, namelijk</label>
                </div>
                <Field className="form-control" name="other_value" type="text" />
            </>
        );
    };

    const getInput = (question) => {
        switch (question.type) {
            case "multiple_choice":
                return (
                    <div className="mt-2">
                        {question.options.map((option, index) => (
                            <div className="radio" key={index}>
                                <Field name="value" value={option.id} id={option.id} type="radio" className="form-control" />
                                <label htmlFor={option.id}> {option.title} </label>
                            </div>
                        ))}
                        {question.has_other_option === 1 ? getOtherInputBlock("radio") : ""}
                    </div>
                );

            case "multiple_answer":
                return (
                    <div className="mt-2">
                        {question.options.map((option, index) => (
                            <div className="checkbox" key={index}>
                                <Field name="value" value={option.id} id={option.id} type="checkbox" className="form-control" />
                                <label htmlFor={option.id}> {option.title} </label>
                            </div>
                        ))}
                        {question.has_other_option === 1 ? getOtherInputBlock("checkbox") : ""}
                    </div>
                );
            default: //open
                return <Field autoFocus={true} name="value" type="text" className="form-control" />;
        }
    };

    return (
        <div>
            <Formik
                initialValues={{
                    other: "",
                    other_value: "",
                    value: "",
                    email: email,
                    token: token,
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="content">
                            <h3 className="subtitle">Vraag {number}</h3>
                            <h1>{question?.title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: question?.description }} />

                            <div className="form-group">
                                <label htmlFor="">Jouw antwoord:</label>
                                {getInput(question)}
                                {errors.value && touched.value && <div className="error">{errors.value}</div>}
                            </div>
                        </div>
                        <div className="buttons_container">
                            <button className="btn kingkong btn-back" type="button" onClick={() => prevStep()}>
                                <img src={arrow_left_gray} alt="arrow" />
                                <span style={{ paddingLeft: "10px" }}>Terug</span>
                            </button>
                            <button type="submit" className="btn btn-primary kingkong">
                                <span>Volgende</span>
                                <img src={arrow_right_white} alt="arrow" />
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}
