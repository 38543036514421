import React from "react";
import { useMutation } from "react-query";
import { postRequestUnsubscribe } from "../../../api/api";
import Banner from "../../components/Banner";
import Content from "../../components/Content";
import logo from "../../../assets/images/logo_lo_color.png";
import { Notify } from "../../../utils/notify";
import Form from "./parts/Form";

export default function RequestUnsubscribe() {
    const requestUnsubscribeMutation = useMutation((values) => postRequestUnsubscribe(values));

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        requestUnsubscribeMutation.mutate(values, {
            onSuccess: (data) => {
                setSubmitting(false);
            },
            onError: (error) => {
                Notify.error("Het uitschrijven is mislukt, probeer het later nogmaals.");
                setSubmitting(false);
            },
        });
    };

    return (
        <section className="unsubscribe">
            <Banner
                component={
                    <>
                        <img className="logo" src={logo} alt="logo" />
                        {requestUnsubscribeMutation.isSuccess ? (
                            <>
                                <h1>Uitschrijven</h1>
                                <p>
                                    Je hebt van ons een e-mail ontvangen met daarin instructies om je uit te schrijven.
                                    <br />
                                    <br />
                                    Geen e-mail ontvangen? Controleer je spam folder. Nog steeds niks gevonden?{" "}
                                    <a href="https://oudersenonderwijs.nl/contact" target="_blank">
                                        Neem dan contact met ons op.
                                    </a>
                                </p>
                            </>
                        ) : (
                            <>
                                <h1 className="form-intro">Uitschrijven</h1>
                                <p className="form-intro">
                                    Jammer dat je je uit wil schrijven bij het Landelijk Ouderpanel. We horen graag wat hiervoor de reden is, zodat we
                                    ons panel kunnen blijven verbeteren.
                                </p>
                                <Form onSubmit={onSubmit} />
                            </>
                        )}
                    </>
                }
            />

            <Content
                title="Wat is het Landelijk Ouderpanel?"
                text="Met het Landelijk Ouderpanel brengt Ouders & Onderwijs de mening en ervaringen van ouders over het huidige onderwijs in kaart. De resultaten nemen we mee in gesprekken en contacten met professionals, overheid en politiek. En we verbeteren de beschikbare informatie over opvoeden en onderwijs. We zijn dan ook erg benieuwd welke vragen u als ouders heeft en welke overige informatie belangrijk voor u is. Het Landelijk Ouderpanel is een initiatief van Ouders & Onderwijs."
            />
        </section>
    );
}
