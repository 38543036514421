import React, { createContext, useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { MutationCache, QueryCache } from "react-query";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const token = localStorage.getItem("token");

    const [authenticated, setAuthenticated] = useState(token !== null);

    const login = (token) => {
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        setAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem("token");
        axios.defaults.headers.common["Authorization"] = "";
        setAuthenticated(false);
    };

    return <AuthContext.Provider value={{ token, authenticated, login, logout }}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    return useContext(AuthContext);
};

const useUnauthenticatedRoute = (element, elseReturn = <Navigate to="/" />) => {
    const { authenticated } = useContext(AuthContext);
    return !authenticated ? element : elseReturn;
};

const useAuthenticatedRoute = (element, elseReturn = <Navigate to="/" />) => {
    const { authenticated } = useContext(AuthContext);
    return authenticated ? element : elseReturn;
};

export { AuthProvider, useAuth, useAuthenticatedRoute, useUnauthenticatedRoute };
