import React, { useState } from "react";
import Banner from "../../components/Banner";
import IntroductionContent from "../../components/IntroductionContent";
import CreatePasswordContent from "./parts/Content";

export default function CreatePassword() {
    return (
        <section className="register">
            <Banner component={<CreatePasswordContent />} />

            <IntroductionContent />
        </section>
    );
}
