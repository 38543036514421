import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import arrow_left_gray from "../../../assets/images/icons/arrow_left_gray.svg";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";

export default function Outro({ campaign, prevStep }) {
    useEffect(() => {
        if (campaign?.link) {
            setTimeout(function () {
                window.open(campaign.link, "_blank");
            }, 5000);
        }
    }, []);

    return (
        <div>
            <div className="content">
                <h1>Bedankt voor het meedoen!</h1>
                <p>We vinden het fijn dat je de moeite neemt om je mening met ons te delen.</p>

                {campaign?.link && (
                    <p>
                        Over 5 seconden word je automatisch doorgestuurd. Gebeurt dit niet?{" "}
                        <a target="_blank" href={campaign.link}>
                            Klik dan hier
                        </a>
                    </p>
                )}
            </div>
            <div className="buttons_container">
                <Link to={"/"} className="btn btn-primary kingkong">
                    <span>Onderzoek afsluiten</span>
                    <img src={arrow_right_white} alt="arrow" />
                </Link>
            </div>
        </div>
    );
}
