import Popup from "reactjs-popup";

export default function NoChildrenPopup({ open, setOpen }) {
    return (
        <Popup open={open} onClose={() => setOpen(false)}>
            <h2 className="title">Helaas kan je niet meedoen</h2>
            <p>
                De onderzoeken in het Landelijk Ouderpanel zijn bedoeld voor ouders met kinderen jonger dan 23 jaar. Helaas kunnen we je daarom niet
                uitnodigen voor onze onderzoeken.
                <br />
                <br />
                Wil je meer informatie over onderwijs? Bekijk dan{" "}
                <a target="_blank" href="https://oudersenonderwijs.nl">
                    de website van Ouders & Onderwijs
                </a>
                .
            </p>
        </Popup>
    );
}
