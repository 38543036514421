import { Field, Formik, yupToFormErrors } from "formik";
import React from "react";
import { useState } from "react";
import Popup from "reactjs-popup";
import Select from "../../../../components/Select";
import { useEffect } from "react";
import arrow_right_white from "../../../../assets/images/icons/arrow_right_white.svg";
import arrow_left_gray from "../../../../assets/images/icons/arrow_left_gray.svg";
import spinner_white from "../../../../assets/images/icons/spinner_white.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { validateRegistration } from "../../../../utils/validation";
import AttributeFormGroup from "./AttributeFormGroup";
import NoChildrenPopup from "../../../components/NoChildrenPopUp";
import { Link } from "react-router-dom";

export default function Form({ pagedAttributes, onSubmit }) {
    const [step, setStep] = useState(1);
    const [popupOpen, setPopupOpen] = useState(false);
    const [childrenAmount, setChildrenAmount] = useState(1);

    const updatedChildrenAmountInputs = (attribute, option, setFieldValue) => {
        if (option.label !== "0") {
            setFieldValue(`attributes[${attribute.key}]`, option.value);
            let amount = option.label === "Meer dan 5" ? 6 : parseInt(option.label);
            setFieldValue("attributes[dob_children]", new Array(amount).fill(""));
            setChildrenAmount(amount);
        } else {
            if (setPopupOpen !== null) setPopupOpen(true);
        }
    };

    const nextStep = async (values, setSubmitting, setErrors) => {
        try {
            setSubmitting(true);
            await validateRegistration(values, Object.values(pagedAttributes)[step - 2]?.map((attribute) => attribute.key) ?? []);
        } catch (ex) {
            setErrors(yupToFormErrors(ex));
            return;
        } finally {
            setSubmitting(false);
        }

        // Step starts at 1, first step is not used by attributes, length always -1.
        if (step - 2 < Object.values(pagedAttributes).length - 1) {
            setStep(step + 1);
            setErrors({});
        } else {
            onSubmit(values, setSubmitting, setErrors);
        }
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const nextButton = (values, isSubmitting, setSubmitting, setErrors, page) => (
        <button type="button" className="btn btn-primary kingkong" onClick={!isSubmitting ? () => nextStep(values, setSubmitting, setErrors) : null}>
            {isSubmitting ? (
                <div className="spinner">
                    <img src={spinner_white} alt="spinner" />
                </div>
            ) : (
                <>
                    <span> {step - 2 < Object.values(pagedAttributes).length - 1 ? "Volgende" : "Aanmelden"}</span>{" "}
                    <img src={arrow_right_white} alt="arrow" />
                </>
            )}
        </button>
    );

    return (
        <div className="register_form">
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    invitation_method: "whatsapp",
                    phone: "",
                    attributes: Object.values(pagedAttributes)
                        .flat()
                        .map((attribute) => {
                            if (attribute.key === "children_amount") {
                                return { [attribute.key]: attribute.options.find((o) => o.title == "1")?.id ?? "" };
                            } else if (attribute.key === "dob_children") {
                                return { [attribute.key]: [] };
                            } else if (attribute.type === "checkbox") {
                                return { [attribute.key]: false };
                            } else {
                                return { [attribute.key]: "" };
                            }
                        })
                        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values, errors, setErrors, setFieldValue, handleSubmit, isSubmitting, setSubmitting, initialValues }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="steps">
                            <div className={"step " + (step == 1 && "active")}>
                                <div className="form-group">
                                    <label htmlFor="name">Voornaam</label>
                                    <Field name="name" type="text" className="form-control" />
                                    {errors?.name && <div className="error">{errors.name}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">E-mailadres *</label>
                                    <Field name="email" type="email" className="form-control" />
                                    {errors?.email && <div className="error">{errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Wachtwoord *</label>
                                    <p className="description">Minstens 8 karakters, 1 hoofdletter, 1 nummer en 1 speciaal teken.</p>
                                    <Field name="password" type="password" className="form-control" />
                                    {errors?.password && <div className="error">{errors.password}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Hoe wil je uitnodigingen voor onderzoek ontvangen?</label>
                                    <Select
                                        name="invitation_method"
                                        defaultValue={{ value: "whatsapp", label: "WhatsApp" }}
                                        options={[
                                            { value: "whatsapp", label: "WhatsApp" },
                                            { value: "email", label: "E-mail" },
                                        ]}
                                        onChange={(option) => setFieldValue("invitation_method", option.value)}
                                    />
                                    {errors?.invitation_method && <div className="error">{errors.invitation_method}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Telefoonnummer {values.invitation_method === "whatsapp" ? "*" : ""}</label>
                                    <PhoneInput
                                        defaultCountry="NL"
                                        name="phone"
                                        className="form-control"
                                        onChange={(value) => setFieldValue("phone", value ?? "")}
                                    />
                                    {errors?.phone && <div className="error">{errors.phone}</div>}
                                </div>

                                <div className="buttons_container">
                                    <Link type="button" className="btn btn-back kingkong" to="/">
                                        <img src={arrow_left_gray} alt="arrow" />
                                    </Link>
                                    {nextButton(values, isSubmitting, setSubmitting, setErrors)}
                                </div>
                            </div>

                            {pagedAttributes &&
                                Object.entries(pagedAttributes).map(([page, attributes]) => {
                                    //Step starts at 2
                                    return (
                                        <div key={page} className={"step " + (step == page && "active")}>
                                            {attributes.map((attribute, index) => (
                                                <AttributeFormGroup
                                                    key={index}
                                                    sleutel={`${page}-${index}`}
                                                    attribute={attribute}
                                                    setFieldValue={setFieldValue}
                                                    value={
                                                        ["children_amount", "dob_children"].includes(attribute.key) ? childrenAmount + "" : undefined
                                                    }
                                                    errors={errors}
                                                    onChangeCallback={(attribute, option) =>
                                                        updatedChildrenAmountInputs(attribute, option, setFieldValue)
                                                    }
                                                />
                                            ))}

                                            <div className="buttons_container">
                                                <button type="button" className="btn btn-back kingkong" onClick={() => prevStep()}>
                                                    <img src={arrow_left_gray} alt="arrow" />
                                                </button>
                                                {nextButton(values, isSubmitting, setSubmitting, setErrors)}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </form>
                )}
            </Formik>

            <NoChildrenPopup open={popupOpen} setOpen={setPopupOpen} />
        </div>
    );
}
